import React from 'react'

import Hero from '../components/Hero';

const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy-section sub-heading-content'>
            <Hero>
                <div className={`privacy-hero`}>
                    <h1 className='text-white text-center hero-heading fadeInDown'>
                        Privacy Policy
                    </h1>
                </div>
            </Hero>
            <div className='privacy-content p-5'>
                <p className=''>
                    Oaknst operates the https://www.tenie.co website (the “Service”). Reference to Tenie (part of Oaknst) includes its current & future associate or subsidiary companies throughout this policy.
                    <br />
                    <br />
                    This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
                    <br />
                    We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from &nbsp;
                    <a href='https://www.tenie.co' className='underline'>
                        https://www.tenie.co
                    </a>
                    .
                    <br />
                    <br />
                    <strong>
                        Information Collection And Use
                    </strong>
                    <br />
                    We collect several different types of information for various purposes to provide and improve our Service to you.
                    <br />
                    <br />
                    <strong>Types of Data Collected</strong>
                    <br />

                    <strong>Personal Data</strong>
                    <br />
                    While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:
                </p>
                <ul className='list-disc px-10'>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                </ul>
                <br />
                <p>
                    <strong>Usage Data</strong>
                    <br />
                    We may also collect information on how the Service is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                    <br />
                    <br />

                    <strong>Use of Data</strong>
                    <br />
                    Tenie (part of Oaknst) uses the collected data for various purposes:
                </p>
                <ul className='list-disc px-10'>
                    <li>
                        To provide and maintain the Service
                    </li>
                    <li>
                        To provide customer care and support
                    </li>
                    <li>
                        To provide analysis or valuable information so that we can improve the Service
                    </li>
                    <li>
                        To monitor the usage of the Service
                    </li>
                    <li>
                        To detect, prevent and address technical issues
                    </li>
                </ul>
                <br />
                <p>
                    <strong>Transfer Of Data</strong>
                    <br />
                    Your information, including Personal Data, may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
                    <br />
                    If you are located outside India and choose to provide information to us, please note that we transfer the data, including Personal Data, to India and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                    <br />
                    Tenie (part of Oaknst) will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
                    <br />
                    <br />

                    <strong>Disclosure Of Data</strong>
                    <br />
                    Legal Requirements
                    <br />
                    Tenie (part of Oaknst) may disclose your Personal Data in the good faith belief that such action is necessary to:
                </p>
                <ul className='list-disc px-10'>
                    <li>
                        To comply with a legal obligation
                    </li>
                    <li>
                        To protect and defend the rights or property of Tenie (part of Oaknst)
                    </li>
                    <li>
                        To prevent or investigate possible wrongdoing in connection with the Service
                    </li>
                    <li>
                        To protect the personal safety of users of the Service or the public
                    </li>
                    <li>
                        To protect against legal liability
                    </li>
                </ul>
                <br />
                <p>
                    <strong>Security Of Data</strong>
                    <br />
                    The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                    <br />
                    <br />

                    <strong>Service Providers</strong>
                    <br />
                    We may employ third-party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services, or to assist us in analyzing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                    <br />
                    <br />

                    <strong>Analytics</strong>
                    <br />
                    We may use third-party Service Providers to monitor and analyze the use of our Service.
                    <br />
                    <br />

                    <strong>Google Analytics</strong>
                    <br />
                    Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network. You can opt out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.
                    <br />
                    For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy?hl=en
                    <br />
                    <br />

                    <strong>Links To Other Sites</strong>
                    <br />
                    Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third-party’s site. We strongly advise you to review the Privacy Policy of every site you visit.
                    <br />
                    We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                    <br />
                    <br />

                    <strong>Children’s Privacy</strong>
                    <br />
                    Our Service does not address anyone under the age of 18 (“Children”).
                    <br />
                    We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children have provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
                    <br />
                    <br />

                    <strong>Changes To This Privacy Policy</strong>
                    <br />
                    We may update our Privacy Policy from time to time without any notification. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                    <br />
                    <br />

                    <strong>Contact Us</strong>
                    <br />
                    If you have any questions about this Privacy Policy, please contact us by sending email to &nbsp;
                    <a href='mailto:info@tenie.co' className='underline'>
                        info@tenie.co
                    </a>
                    .
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy