import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import DS from './pages/products/DS';
import ALM from './pages/products/ALM';
import EDM from './pages/products/EDM';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Register from './pages/register/Register';
import Login from './pages/login/Login';

import BlogNew from './pages/blog/Blogs';
import EditBlog from './pages/blog/EditBlog';
import CreateBlog from './pages/blog/CreateBlog';
import UserBlogs from './pages/blog/UserBlogs';
import BlogDetailNew from './pages/blog/BlogDetailNew';

import AdminUsers from './pages/admin/AdminUsers';
import AdminBlogs from './pages/admin/AdminBlogs';
import AdminContacts from './pages/admin/AdminContacts';

import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
import Flash from './components/flash/Flash';
import Footer from './components/Footer';

import { Authprovider } from './context/auth-context';
import { RequireAuth } from './utils/RequireAuth';

import './App.css';

function App() {
	return (
		<div className="App">
			<Router>
				<ScrollToTop />
				<Authprovider>
					<Navbar />
					<Flash />
					<Routes>
						<Route path='/' element={<Home />} exact />
						<Route path='/about' element={<About />} />
						<Route path='/products/'>
							<Route path='teniePDM' element={<DS />} />
							<Route path='tenieALM' element={<ALM />} />
							<Route path='tenieEDM' element={<EDM />} />
						</Route>
						<Route path='/contact' element={<ContactUs />} />
						<Route path='/privacyPolicy' element={<PrivacyPolicy />} />
						<Route path='/register' element={<Register />} />
						<Route path='/login' element={<Login />} />
						<Route path='/resources/'>
							<Route path='' element={<BlogNew />} />
							<Route path='editBlogs/:blogId'
								element={
									<RequireAuth>
										<EditBlog />
									</RequireAuth>
								}
							/>
							<Route path='createblog'
								element={
									<RequireAuth>
										<CreateBlog />
									</RequireAuth>
								}
							/>
							<Route path='details/:blogId' element={<BlogDetailNew />} />
						</Route>
						<Route path='/userblogs'
							element={
								<RequireAuth>
									<UserBlogs />
								</RequireAuth>
							}
						/>
						<Route path='/admin/'>
							<Route path='users'
								element={
									<RequireAuth>
										<AdminUsers />
									</RequireAuth>
								}
							/>
							<Route path='blogs'
								element={
									<RequireAuth>
										<AdminBlogs />
									</RequireAuth>
								}
							/>
							<Route path='contacts'
								element={
									<RequireAuth>
										<AdminContacts />
									</RequireAuth>
								}
							/>
						</Route>
					</Routes>
					<Footer />
				</Authprovider>
			</Router>
		</div >
	);
}

export default App;
