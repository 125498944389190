import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom';

import { useAuth } from '../../context/auth-context'
import { getBlogs, deleteBlog } from '../../actions/blogs'
import Spinner from '../../components/Spinner'

import './userBlogs.css'

function UserBlogs() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const auth = useAuth()
    const blogs = useSelector((state) => state.blogs)

    const userBlogs = blogs.length ? blogs.filter((blog) => {
        return blog?.user ? blog.user._id === auth.loggedUser._id : null
    }) : []

    const handleDelete = async (e, blog) => {
        e.preventDefault()
        dispatch(deleteBlog(blog))
        navigate('/')
    }

    useEffect(() => {
        dispatch(getBlogs(0))
    }, [dispatch])

    return (
        <div style={{ minHeight: "90vh", padding: "0 130px 50px" }} className='userBlogs'>
            <div className='flex w-full mx-0'>
                <div className='w-1/12 user-blogs-headings'>S.No.</div>
                <div className='w-1/3 user-blogs-headings'>Blog Name</div>
                <div className='w-1/4 user-blogs-headings'>Publish Date</div>
                <div className='w-1/2 user-blogs-headings'>Edit</div>
                <div className='w-1/2 user-blogs-headings' style={{ borderRight: "0" }}>Delete</div>
            </div>
            {
                userBlogs.length ?
                    userBlogs.map((blog, index) => {

                        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                        const str = blog.date;
                        const date = new Date(str)
                        return <div className='flex w-full mx-0' key={index}>
                            <div className='w-1/12 user-blogs-info'>{index}</div>
                            <div className='w-1/3 user-blogs-info'>{blog.title.substring(0, 25)}...</div>
                            <div className='w-1/4 user-blogs-info'>{`${date.getDate()} ${month[date.getMonth()]}, ${date.getFullYear()}`}</div>
                            <div className='w-1/2 user-blogs-info'>
                                <Link to={`/resources/editBlogs/${blog._id}`} className={`user-blogs-btn user-blogs-pst-btn`}>Edit</Link>
                            </div>
                            <div className='w-1/2 user-blogs-info' style={{ borderRight: "0" }}>
                                <form onSubmit={() => handleDelete(blog)}>
                                    <button className='user-blogs-btn user-blogs-ngt-btn'>Delete</button>
                                </form>
                            </div>
                        </div>

                    }) : <div className='flex justify-center al my-5' style={{ height: `662.26px`, paddingTop: "250px" }}><Spinner /></div>
            }
        </div>
    )
}

export default UserBlogs