import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RiMailOpenFill } from 'react-icons/ri';
import { FaMapMarkerAlt, FaLinkedin, FaTwitter } from 'react-icons/fa';
import axios from 'axios';

import { FLASH_ERROR, FLASH_SUCCESS } from '../constants/actionTypes';
import { apiUrl } from '../api';
import Hero from '../components/Hero';


const ContactUs = () => {

    const contactIntial = {
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        phone: '',
        country: '',
        message: '',
        checkbox: false
    };

    const [contactData, setContactData] = useState(contactIntial);
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDispatch();

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setContactData({ ...contactData, [name]: value });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        setFormErrors(validate(contactData));
    };

    const submitContact = async () => {
        try {
            const config = {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const { data } = await axios.post(`${apiUrl}/contactUs`, { contactData }, config)
            if (data.type === "success") {
                setContactData(contactIntial)
                dispatch({ type: FLASH_SUCCESS, payload: data.message })
            }
            else
                dispatch({ type: FLASH_ERROR, payload: data.message })
        }
        catch (err) {
            dispatch({ type: FLASH_ERROR, payload: err.message })
        }
    }

    const validate = (formValues) => {
        let errors = {}
        const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
        const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i;
        if (formValues.firstName.length === 0) {
            errors = { ...errors, firstName: "First Name is required!" }
        }
        if (formValues.lastName.length === 0) {
            errors = { ...errors, lastName: "Last Name is required!" }
        }
        if (formValues.company.length === 0) {
            errors = { ...errors, company: "Company name is required!" }
        }
        if (formValues.email.length === 0) {
            errors = { ...errors, email: "Email is required!" }
        }
        else if (!emailRegex.test(formValues.email)) {
            errors = { ...errors, email: "Please enter a valid email!" }
        }
        if (formValues.phone.length === 0) {
            errors = { ...errors, phone: "Phone is required!" }
        }
        else if (!phoneRegex.test(formValues.phone)) {
            errors = { ...errors, phone: "Enter a valid phone number!" }
        }
        if (formValues.country.length === 0) {
            errors = { ...errors, country: "Country is required!" }
        }
        if (formValues.message.length === 0) {
            errors = { ...errors, message: "Message is required!" }
        }
        if (formValues.message.length > 200) {
            errors = { ...errors, message: "Word limit exceed!" }
        }
        if (!formValues.checkbox) {
            errors = { ...errors, checkbox: "Agree with terms and conditions to proceed!" }
        }
        if (Object.keys(errors).length === 0) {
            submitContact()
        }

        return errors
    }

    return (
        <div className='contact-us border-b-2 border-solid border-white'>
            <section className='contact-hero-section border-b-2 border-solid border-white text-white'>
                <Hero>
                    <div className='fadeInDown'>
                        <h1 className='hero-heading'>Contact Us</h1>
                        <h3 className='text-xl font-normal'>Any questions or remarks?
                            <br />
                            Just drop us a message!</h3>
                    </div>
                </Hero>
            </section>
            <section className='contact-section sub-heading-content'>
                <div className='flex md:flex-row flex-col'>
                    <form className='basis-3/5' onSubmit={submitHandler} >
                        <div className='contact-form-container grid grid-cols-2 gap-x-5 md:gap-x-20 bg-theme-blue text-white p-6 py-4'>
                            {/* First Name */}
                            <div className='first-name-container'>
                                <label htmlFor='firstName'>
                                    First Name
                                    <span className=''>*</span>
                                </label><br />
                                <input type='text'
                                    className='contact-input'
                                    name='firstName'
                                    value={contactData.firstName}
                                    onChange={handleChange}
                                    placeholder=' '
                                    disabled={false}
                                />
                                {formErrors.firstName ? <p className='error-message'>{formErrors.firstName}</p> : <></>}
                            </div>
                            {/* LastName */}
                            <div className='last-name-container'>
                                <label htmlFor='lastName'>
                                    Last Name
                                    <span className=''>*</span>
                                </label><br />
                                <input type='text'
                                    className='contact-input'
                                    name='lastName'
                                    value={contactData.lastName}
                                    onChange={handleChange}
                                    placeholder=''
                                />
                                {formErrors.lastName ? <p className='error-message'>{formErrors.lastName}</p> : <></>}
                            </div>
                            {/* Company */}
                            <div className='company-container'>
                                <label htmlFor='company'>
                                    Company
                                    <span className=''>*</span>
                                </label><br />
                                <input type='text'
                                    className='contact-input'
                                    name='company'
                                    value={contactData.company}
                                    onChange={handleChange}
                                    placeholder=''
                                />
                                {formErrors.company ? <p className='error-message'>{formErrors.company}</p> : <></>}
                            </div>
                            {/* Email */}
                            <div className='email-container'>
                                <label htmlFor='email'>
                                    Email
                                    <span className=''>*</span>
                                </label><br />
                                <input type='email'
                                    className='contact-input'
                                    name='email'
                                    value={contactData.email}
                                    onChange={handleChange}
                                    placeholder=''
                                />
                                {formErrors.email ? <p className='error-message'>{formErrors.email}</p> : <></>}
                            </div>
                            {/* Phone */}
                            <div className='phone-container'>
                                <label htmlFor='phone'>
                                    Phone
                                    <span className=''>*</span>
                                </label><br />
                                <input type='phone'
                                    className='contact-input'
                                    name='phone'
                                    value={contactData.phone}
                                    onChange={handleChange}
                                    placeholder=''
                                />
                                {formErrors.phone ? <p className='error-message'>{formErrors.phone}</p> : <></>}
                            </div>
                            {/* Country */}
                            <div className='country-container'>
                                <label htmlFor='country'>
                                    Country
                                    <span className=''>*</span>
                                </label><br />
                                <input type='text'
                                    className='contact-input'
                                    name='country'
                                    value={contactData.country}
                                    onChange={handleChange}
                                    placeholder=''
                                />
                                {formErrors.country ? <p className='error-message'>{formErrors.country}</p> : <></>}
                            </div>
                            {/* Message */}
                            <div className='message-container col-span-2 '>
                                <label htmlFor='message'>
                                    Message
                                    <span className=''>*</span>
                                </label><br />
                                <textarea type='text'
                                    className='contact-input !w-full c-message placeholder:text-slate-300'
                                    name='message'
                                    rows={6}
                                    value={contactData.message}
                                    onChange={handleChange}
                                    placeholder='Write your message...'
                                ></textarea>
                                {formErrors.message ? <p className='error-message'>{formErrors.message}</p> : <></>}
                            </div>
                            {/* Checkbox */}
                            <div className='checkbox-container col-span-2'>
                                <input type='checkbox'
                                    className='contact-input cb'
                                    name='t&c'
                                    value={contactData.checkbox}
                                    onChange={(e) => setContactData({ ...contactData, checkbox: e.target.checked })}
                                    placeholder=''
                                />
                                &nbsp; I agree to the &nbsp;
                                <Link to='/privacyPolicy' id="privacyLink" className="font-medium" target="_blank">
                                    <u>
                                        Privacy Policy and Other T&C.
                                    </u>
                                    <span className=''>*</span>
                                </Link>
                                {formErrors.checkbox ? <p className='error-message pt-1'>{formErrors.checkbox}</p> : <></>}
                            </div>
                            {/* Mandatory info */}
                            <p className='mandatory-info pl-5 col-span-2'>
                                All fields marked with an (
                                <span className=''>*</span>
                                ) are mandatory.
                            </p>
                            {/* Submit Button */}
                            <div className='submit-btn-container justify-self-end col-span-2'>
                                <button type="submit" className='text-white bg-[#414140] px-4 py-2 text-center rounded-lg mt-4 md:mt-0'>Send Message</button>
                            </div>
                        </div>
                    </form>
                    <div className='contact-info basis-2/5 bg-[#EDEDED] z-1'>
                        <div className='h-full w-full py-10 px-6 relative'>
                            <h1 className='sub-heading mb-10 z-10 relative text-theme-blue'>
                                How to Find Us!
                            </h1>
                            <h3 className='sub-heading-content mb-10 w-2/3 z-10 relative'>
                                Fill up the form if you have any questions, and our team will get back to you.
                            </h3>
                            <p className='py-2 z-10 relative'>
                                <RiMailOpenFill className='inline mr-2 text-theme-blue text-3xl' />
                                <a href='mailTo:info@tenie.co'>
                                    info@tenie.co
                                </a>
                            </p>
                            <p className='py-2 z-10 relative'>
                                <FaMapMarkerAlt className='inline mr-2 text-theme-blue text-3xl' />
                                Gurgaon, Sec-53, Golf Course Road
                            </p>
                            <div className='contact-social-link flex mt-10 gap-x-10 z-10 relative'>
                                <a href={'https://www.linkedin.com/company/oaknst/'} target='_blank' rel="noreferrer">
                                    <span className='p-2 rounded-lg bg-theme-blue'>
                                        <FaLinkedin className='inline bg-theme-blue text-white text-2xl' />
                                    </span>
                                </a>
                                <a href={'https://twitter.com/oaknst'} target='_blank' rel="noreferrer">
                                    <span className='p-2 rounded-lg bg-theme-blue'>
                                        <FaTwitter className='inline bg-theme-blue text-white text-2xl' />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ContactUs