import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import RichTextEditor from '../../components/RichTextEditor';

import { updateBlog } from '../../actions/blogs';
import { useAuth } from '../../context/auth-context';

function EditBlog() {

    const { blogId } = useParams()
    const auth = useAuth()

    const blogs = useSelector((state) => state.blogs);
    console.log(blogs);

    const blog = blogs.filter((blog) => {
        return blog._id === blogId
    })[0]

    const [blogBody, setBlogBody] = useState(blog.body)

    const [blogData, setBlogData] = useState({ title: blog.title, image: '', category: blog.category, summary: blog.summary, body: blogBody })
    const [formErrors, setFormErrors] = useState({})

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormErrors(blogValidation(blogData))
    }

    const blogValidation = (blog) => {
        let errors = {}

        if (blog.title.length === 0) {
            errors = { ...errors, title: 'Title is empty!' }
        }
        if (blog.image.length === 0) {
            errors = { ...errors, image: 'Image  is not selected!' }
        }
        if (blog.category.length === 0) {
            errors = { ...errors, category: 'Category is not selected!' }
        }
        if (blog.summary.length === 0) {
            errors = { ...errors, summary: 'Summary is empty!' }
        }
        if (blog.body.length === 0) {
            errors = { ...errors, body: 'Blog content is empty!' }
        }

        if (Object.keys(errors).length === 0) {
            submitBlog()
        }

        return errors
    }

    const submitBlog = () => {
        const formData = new FormData();

        formData.append('title', blogData.title)
        formData.append('image', blogData.image)
        formData.append('category', blogData.category)
        formData.append('summary', blogData.summary)
        formData.append('body', blogData.body)
        formData.append('author', auth.loggedUser._id)
        formData.append('blogId', blogId)
        dispatch(updateBlog(formData))

        navigate('/resources')
    }

    useEffect(() => {
        setBlogData({ ...blogData, body: blogBody })
    }, [blogBody]);

    return (
        <div className='blog-content editBlog mb-5' style={{ overflow: "auto" }}>
            <div className='flex justify-center'>
                <div className='w-full md:w-3/4'>
                    <div className='flex flex-col justify-start items-top m-5' style={{ height: "90vh" }}>
                        <div className='flex items-center bg-gradient-to-r from-theme-bo to-white'>
                            <h2 className='m-1 ml-2' style={{ color: "white" }}>
                                Edit Blog
                            </h2>
                        </div>
                        <form onSubmit={handleSubmit} encType='multipart/form-data' validate="true">
                            <div className='w-full my-5'>
                                <div className='w-1/4'>
                                    Choose Banner:
                                </div>
                                <div className="w-3/4">
                                    <input type="file" onChange={(e) => setBlogData({ ...blogData, image: e.target.files[0] })} />
                                    <div className="blog-form-error">{formErrors.image ? formErrors.image : null}</div>
                                </div>
                            </div>
                            <div className='w-full my-5'>
                                <div className='w-1/4'>
                                    Enter Title:
                                </div>
                                <div className='w-3/4'>
                                    <input className="form-control" type="text" value={blogData.title} placeholder='Enter Title' onChange={(e) => setBlogData({ ...blogData, title: e.target.value })} />
                                    <div className="blog-form-error">{formErrors.title ? formErrors.title : null}</div>
                                </div>

                            </div>
                            <div className='w-full my-5'>
                                <div className='w-1/4'>
                                    Choose Category:
                                </div>
                                <div className='w-3/4'>
                                    <select value={blog.category} onChange={(e) => setBlogData({ ...blogData, category: e.target.value })} className="form-select" aria-label="Default select example">
                                        <option value="none" disabled>Open this select menu</option>
                                        <option value="Automation">Automation</option>
                                        <option value="Asset Optimization">Asset Optimization</option>
                                        <option value="Operations Optimization">Operations Optimization</option>
                                        <option value="Data Sheets">Data Sheets</option>
                                    </select>
                                    <div className="blog-form-error">{formErrors.category ? formErrors.category : null}</div>
                                </div>
                            </div>
                            <div className='w-full my-5'>
                                <div className='w-1/4'>
                                    Write Summary:
                                </div>
                                <div className='w-3/4'>
                                    <textarea value={blogData.summary} className='form-control' placeholder='Write summary' onChange={(e) => setBlogData({ ...blogData, summary: e.target.value })} />
                                    <div className="blog-form-error">{formErrors.summary ? formErrors.summary : null}</div>
                                </div>
                            </div>
                            <div className='my-3'>
                                <label className='mb-3'>
                                    Edit Blog Content:
                                </label>
                                <RichTextEditor className="editorPanel" initialValue={blog.body} setBlogBody={setBlogBody} />
                                <div className="blog-form-error">{formErrors.body ? formErrors.body : null}</div>
                            </div>
                            <div className='my-5'>
                                <button className="submit-btn bg-theme-bo hover:bg-theme-orange-hover text-white border-0 px-5 py-2 text-xl" type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditBlog