import React, { useEffect, useRef, useState } from 'react'

const Section2 = ({ heading, pageData, page }) => {

    const aniRef = useRef();
    const [showAni, setShowAni] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setShowAni(entry.isIntersecting);
        });
        observer.observe(aniRef.current);
    }, []);


    return (
        <section className='honeyComb-section xl:px-32 section-container'>
            <h1 className='pb-2 text-center'>
                <span className='section-heading bg-[#D9D9D9]'>
                    {heading}
                </span>
            </h1>
            <div className={`${page + '-grid'} our-promise grid w-full ml-0 md:grid-rows-2 md:grid-cols-3 mx-20`} ref={aniRef}>
                <div className={`${showAni && 'gear-ani'} text-center mt-10 mx-4`}>
                    <div className=''>
                        <img className='mx-auto' src={pageData[0].src} alt='pageData[0]' />
                    </div>
                    <div className=''>
                        <h3 className='sub-heading py-1.5'>
                            {pageData[0].heading}
                        </h3>
                        <p className='sub-heading-content'>
                            {pageData[0].content}
                        </p>
                    </div>
                </div>

                <div className='row-span-2 mt-10 hidden md:block'>
                    <img className='mx-auto h-full' src="/assets/Gear.svg" alt="" />
                </div>

                <div className={`${showAni && 'gear-ani'} text-center mt-10 mx-4`}>
                    <div className=''>
                        <img className='mx-auto' src={pageData[1].src} alt='pageData[1]' />
                    </div>
                    <div className=''>
                        <h3 className='sub-heading py-1.5'>
                            {pageData[1].heading}
                        </h3>
                        <p className='sub-heading-content'>
                            {pageData[1].content}
                        </p>
                    </div>
                </div>
                <div className={`${showAni && 'gear-ani'} text-center mt-10 mx-4`}>
                    <div className=''>
                        <img className='mx-auto' src={pageData[2].src} alt='pageData[2]' />
                    </div>
                    <div className=''>
                        <h3 className='sub-heading py-1.5'>
                            {pageData[2].heading}
                        </h3>
                        <p className='sub-heading-content'>
                            {pageData[2].content}
                        </p>
                    </div>
                </div>
                <div className={`${showAni && 'gear-ani'} text-center mt-10 mx-4`}>
                    <div className=''>
                        <img className='mx-auto' src={pageData[3].src} alt='pageData[3]' />
                    </div>
                    <div className=''>
                        <h3 className='sub-heading py-1.5'>
                            {pageData[3].heading}
                        </h3>
                        <p className='sub-heading-content'>
                            {pageData[3].content}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section2