import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import Spinner from "../../components/Spinner";

import { deleteBlog } from "../../actions/blogs";
import { useAuth } from "../../context/auth-context";
import { apiUrl } from "../../api";

import "./blogDetail.css";

function BlogDetailNew() {
  const { blogId } = useParams();
  const auth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [blog, setBlog] = useState();

  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const getBlogDetails = async () => {
    const { data } = await axios.get(
      `${apiUrl}/blogs/details/${blogId}`,
      config
    );
    setBlog(data);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    dispatch(deleteBlog(blog));
    navigate("/");
  };

  useEffect(() => {
    if (!blog) {
      getBlogDetails(0);
    }

    if (document.getElementById("blogContainer") && blog) {
      const blogContainer = document.getElementById("blogContainer");
      blogContainer.innerHTML = blog.body;
      console.log(blogContainer.innerHTML);

      if (document.getElementById("blogDate")) {
        const month = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const str = blog.date;
        const date = new Date(str);
        const blogDate = document.getElementById("blogDate");
        blogDate.innerHTML = `${date.getDate()} ${
          month[date.getMonth()]
        }, ${date.getFullYear()}`;
      }
    }
  });
  return (
    <div className="flex flex-col blogDetailNew">
      {blog ? (
        <>
          <div
            className="blog-banner"
            style={{
              background: `no-repeat center/cover url("${blog?.image}")`,
            }}
          >
            <div className="blog-banner-content">
              <div className="blog-banner-category">{blog.category}</div>
              <div className="blog-banner-title">
                <u>{blog.title}</u>
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="blog-username">
              By&nbsp;
              <span style={{ fontStyle: "italic" }}>{blog.username}</span>
            </div>
            <div className="blog-date" id="blogDate"></div>
          </div>
          <div style={{ height: "5px", background: "#D9D9D9" }} />
          <div id="blogContainer"> </div>
          {auth.loggedUser &&
          (auth.loggedUser._id === process.env.REACT_APP_ADMIN ||
            (blog.user ? blog.user._id === auth.loggedUser._id : false)) ? (
            <div className="flex justify-end gap-2 mb-4 mr-4">
              <Link
                to={`/resources/editBlogs/${blogId}`}
                className="btn bg-theme-blue hover:bg-theme-blue-hover"
                state={{ blog }}
              >
                Edit Blog
              </Link>
              <form onSubmit={handleDelete}>
                <button
                  className="ml-4 btn bg-theme-bo hover:bg-theme-orange-hover"
                  state={{ id: blog._id }}
                >
                  Delete Blog
                </button>
              </form>
            </div>
          ) : null}
        </>
      ) : (
        <div
          className="flex justify-center al my-5"
          style={{ height: `662.26px`, paddingTop: "250px" }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default BlogDetailNew;
