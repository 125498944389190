import React, { useState } from 'react'
import { AiFillCopyrightCircle } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { apiUrl } from '../api';
import { FLASH_ERROR, FLASH_SUCCESS } from '../constants/actionTypes';

const Footer = () => {

    const contactIntial = {
        firstName: '',
        lastName: '',
        company: 'N/A',
        email: '',
        phone: '',
        country: 'N/A',
        message: '',
    };

    const [contactData, setContactData] = useState(contactIntial);
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDispatch();

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setContactData({ ...contactData, [name]: value });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        setFormErrors(validate(contactData));
        if (formErrors) {
            let errorMessage = "";
            errorMessage += Object.keys(formErrors).join(", ");
            errorMessage += " is required!";
            dispatch({ type: FLASH_ERROR, payload: errorMessage });
        }
    };

    const submitContact = async () => {
        try {
            const config = {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const { data } = await axios.post(`${apiUrl}/contactUs`, { contactData }, config)
            if (data.type === "success") {
                setContactData(contactIntial)
                dispatch({ type: FLASH_SUCCESS, payload: data.message })
            }
            else
                dispatch({ type: FLASH_ERROR, payload: data.message })
        }
        catch (err) {
            dispatch({ type: FLASH_ERROR, payload: err.message })
        }
    }

    const validate = (formValues) => {
        let errors = {}
        const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
        const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i;
        if (formValues.firstName.length === 0) {
            errors = { ...errors, firstName: "First Name is required!" }
        }
        if (formValues.lastName.length === 0) {
            errors = { ...errors, lastName: "Last Name is required!" }
        }
        if (formValues.email.length === 0) {
            errors = { ...errors, email: "Email is required!" }
        }
        else if (!emailRegex.test(formValues.email)) {
            errors = { ...errors, email: "Please enter a valid email!" }
        }
        if (formValues.phone.length === 0) {
            errors = { ...errors, phone: "Phone is required!" }
        }
        else if (!phoneRegex.test(formValues.phone)) {
            errors = { ...errors, phone: "Enter a valid phone number!" }
        }
        if (formValues.message.length === 0) {
            errors = { ...errors, message: "Message is required!" }
        }
        if (formValues.message.length > 200) {
            errors = { ...errors, message: "Word limit exceed!" }
        }
        if (Object.keys(errors).length === 0) {
            submitContact()
        }

        return errors
    }

    return (
        <footer className='footer-section flex justify-between md:flex-row flex-col'>
            <div className="left-section basis-1/2 p-10 py-8 pt-9">
                <div className="left-container md:px-10 ">
                    <img className='scale-110'
                        src='/assets/Logo.png' alt='Tenie Logo' />
                    <div className='footer-links grid grid-cols-2 gap-y-2 mt-2'>
                        <Link to='/products/teniePDM'>
                            <p className='sub-heading-content'>Tenie PDM</p>
                        </Link>
                        <Link to='/'>
                            <p className='sub-heading-content'>Home</p>
                        </Link>
                        <Link to='/products/tenieALM'>
                            <p className='sub-heading-content'>Tenie ALM</p>
                        </Link>
                        <Link to='/about'>
                            <p className='sub-heading-content'>About</p>
                        </Link>
                        <Link to='/products/tenieEDM'>
                            <p className='sub-heading-content'>Tenie EDM</p>
                        </Link>
                        <Link to='/resources'>
                            <p className='sub-heading-content'>Resources</p>
                        </Link>
                    </div>
                    <p className='sub-heading-content mt-10'>
                        <AiFillCopyrightCircle className='inline mr-1 mb-1' />
                        2023 Oaknst. All rights reserved.</p>
                </div>
            </div>
            <div className="right-section basis-1/2 bg-theme-blue text-white md:px-16 px-8 py-2">
                <h1 className='in-touch text-base font-semibold py-2 text-center'>
                    Get In Touch
                </h1>
                <div className="form-container pb-3 text-sm">
                    <form className='get-in-touch-form grid grid-cols-2 md:gap-x-24 gap-x-6 gap-y-2' onSubmit={submitHandler}>
                        <div className=''>
                            <label htmlFor='firstName'>First Name</label>
                            <input
                                className=''
                                name='firstName'
                                type='text'
                                value={contactData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className=''>
                            <label htmlFor='lastName'>Last Name</label>
                            <input
                                className=''
                                name='lastName'
                                type='text'
                                value={contactData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className=''>
                            <label htmlFor='email'>Email</label>
                            <input
                                className=''
                                name='email'
                                type='email'
                                value={contactData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className=''>
                            <label htmlFor='phone'>Mobile</label>
                            <input
                                className=''
                                name='phone'
                                type='phone'
                                value={contactData.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className='col-span-2 mt-2'>
                            <label htmlFor='message' className='col-span-2 pt-1'></label>
                            <textarea
                                className='w-full pt-1 pl-1'
                                rows={2}
                                name='message'
                                type='text'
                                placeholder='Write your message...'
                                value={contactData.message}
                                onChange={handleChange}
                            ></textarea>
                            <button type='submit' className='send-btn text-white px-1 py-1 w-20 mt-3'>
                                Send
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </footer>
    )
}

export default Footer