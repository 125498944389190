import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../components/Spinner";
import { getBlogs } from "../../actions/blogs";
import { apiUrl } from "../../api/index";

import "./blogs.css";

function BlogNew() {
  const dispatch = useDispatch();
  const allBlogs = useSelector((state) => state.blogs);
  const [filter, setFilter] = useState("All");
  const [blogIndex, setBlogIndex] = useState(0);
  const [flag, setFlag] = useState(true);
  const categories = [
    "All",
    "Automation",
    "Asset Optimization",
    "Operations Optimization",
    "Data Sheets",
  ];

  const loadMore = () => {
    setBlogIndex(blogIndex + 6);
  };

  useEffect(() => {
    dispatch(getBlogs(0));
    if (blogIndex > allBlogs.length) {
      setFlag(false);
      console.log("flag: ", flag);
    }
  }, [dispatch, blogIndex]);

  return (
    <div className="flex flex-col blogs">
      {allBlogs.length ? (
        <>
          <div
            className="first-blog"
            style={{
              background: `no-repeat center/cover url("${allBlogs[0].image}")`,
            }}
          >
            <div className="first-blog-content">
              <div className="first-blog-category">{allBlogs[0].category}</div>
              <div className="first-blog-title">
                <u>{allBlogs[0].title}</u>
              </div>
              <div style={{ width: "100%" }}>
                <Link
                  to={`/resources/details/${allBlogs[0]._id}`}
                  state={{ id: allBlogs[0]._id }}
                >
                  <button className="first-blog-readMore">Read More</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap categories">
            {categories.map((category, idx) => {
              return (
                <div
                  className="categories-name font-medium"
                  key={idx}
                  onClick={(e) => {
                    setFilter(category);
                  }}
                >
                  {category}
                </div>
              );
            })}
          </div>
          <div className="flex w-full">
            {allBlogs.map((blog, index) => {
              if (index !== 0 && (filter === "All" || blog.category === filter))
                return (
                  <div
                    className="basis-full sm:basis-1/2 xl:basis-1/3"
                    key={index}
                  >
                    <div className="flex flex-col items-start justify-between blogs-column">
                      {blog.image ? (
                        <div style={{ width: "100%" }}>
                          <img src={`${blog.image}`} />
                        </div>
                      ) : null}
                      <div className="blog-category">{blog.category}</div>
                      <div className="blog-title">{blog.title}</div>
                      <div className="blog-summary">{blog.summary}</div>
                      <Link
                        to={`/resources/details/${blog._id}`}
                        state={{ id: blog._id }}
                      >
                        <button className="readMore">Read More</button>
                      </Link>
                    </div>
                  </div>
                );
              else return <Fragment key={index}></Fragment>;
            })}
          </div>
        </>
      ) : (
        <div
          className="flex justify-center al my-5"
          style={{ height: `662.26px`, paddingTop: "250px" }}
        >
          <Spinner />
        </div>
      )}
      {flag ? (
        <div
          className="flex justify-center"
          style={{ cursor: "pointer", margin: "80px 0 30px" }}
        >
          <div onClick={loadMore} id="load-more">
            <div>Load More</div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center" style={{ margin: "30px 0" }}>
          --------------------End of Page--------------------
        </div>
      )}
    </div>
  );
}

export default BlogNew;
