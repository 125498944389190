import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BiChevronDown, BiMenu } from 'react-icons/bi';
import axios from 'axios';

import { apiUrl } from '../api/index';
import { useAuth } from '../context/auth-context';
import { FLASH_SUCCESS, FLASH_ERROR } from '../constants/actionTypes';
import { NavLinks } from '../constants/NavLinks';

const Navbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useAuth();

    const [toggle, setToggle] = useState(false);

    const handleLogout = async () => {
        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.get(`${apiUrl}/logout`, config)

        if (data.type === 'success')
            auth.logOut()

        if (data.type === "success") {
            dispatch({ type: FLASH_SUCCESS, payload: data.message })
        }
        else
            dispatch({ type: FLASH_ERROR, payload: data.message })

        navigate('/', { replace: true })
        toggleNavbar();
    }

    function toggleNavbar() {
        setToggle(prev => !prev);
    }

    return (
        <nav className='nav-container flex justify-between bg-transparent mt-2 md:mt-5 items-center z-[100] flex-wrap lg:flex-nowrap flex-col lg:flex-row'>
            <div className={`flex justify-between w-full lg:w-max`}>
                <Link to={'/'}>
                    <img className='nav-logo-image ml-3 scale-110' src='/assets/Logo.png' alt='Logo' />
                </Link>
                <span className={`text-3xl grid place-items-center`} onClick={toggleNavbar}>
                    <BiMenu className='border border-solid bg-white rounded-sm mr-1 block lg:hidden' />
                </span>
            </div>
            <div className={`links-container ${toggle ? '' : 'hidden lg:block'} w-full lg:w-max`}>
                <ul className='ul-links-container flex-wrap flex text-sm font-normal items-center bg-theme-blue text-white md:rounded-y-xl md:rounded-l-xl'>
                    {NavLinks.map(singleNav =>
                        <li key={singleNav.id} className={`nav-link ${singleNav.dropdown && 'products relative'}`}>
                            <Link to={singleNav.to} onClick={singleNav.id === 3 ? null : toggleNavbar}
                                className='transition-all ease-in-out relative'
                            >{singleNav.name}
                                {singleNav.dropdown &&
                                    <BiChevronDown className='dropdown-icon inline text-theme-bo text-2xl -mr-4' />
                                }
                            </Link>
                            {singleNav.dropdown &&
                                <ul className='nav-dropdown absolute text-white w-max'>
                                    {singleNav.dropdown.map(service =>
                                        <li key={service.id} className='nav-dropdown-item'>
                                            <Link to={`products${service.to}`} onClick={toggleNavbar}>{service.name}</Link>
                                        </li>
                                    )}
                                </ul>
                            }
                        </li>
                    )}
                    {
                        (auth.loggedUser && (process.env.REACT_APP_ADMIN === auth.loggedUser._id)) ?
                            <li className="nav-link admin-link">
                                <Link className='transition-all ease-in-out'>
                                    Admin
                                    <BiChevronDown className='inline text-theme-bo text-2xl -mr-4' />
                                </Link>
                                <div className='nav-dropdown'>
                                    <Link to='/admin/blogs' onClick={toggleNavbar}>
                                        <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                            Blogs
                                        </div>
                                    </Link>
                                    <Link to='/admin/users' onClick={toggleNavbar}>
                                        <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                            Users
                                        </div>
                                    </Link>
                                    <Link to='/admin/contacts' onClick={toggleNavbar}>
                                        <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                            Contacts
                                        </div>
                                    </Link>
                                </div>
                            </li>
                            : null
                    }
                    {
                        auth.loggedUser ?
                            <>
                                <li className="nav-link blogs-link">
                                    <Link className='transition-all ease-in-out'>
                                        <div>Blogs
                                            <BiChevronDown className='inline text-theme-bo text-2xl -mr-4' />
                                        </div>
                                    </Link>
                                    <div className='nav-dropdown'>
                                        <Link to="/userblogs" onClick={toggleNavbar}>
                                            <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                                My Blogs
                                            </div>
                                        </Link>
                                        <Link to='/resources/createblog' onClick={toggleNavbar}>
                                            <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" className='nav-dropdown-item'>
                                                Create Blog
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li className="nav-link">
                                    <button href="#"
                                        className='transition-all ease-in-out'
                                        onClick={handleLogout}>
                                        Logout
                                    </button>
                                </li>
                            </>
                            :
                            <li className="nav-link">
                                <Link to="/login" className='header-links header-links-2 transition-all ease-in-out'>
                                    Login
                                </Link>
                            </li>
                    }
                </ul>
            </div>

        </nav>
    )
}

export default Navbar