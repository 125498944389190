const homeImageDir = '/assets/Home';

const WHY_US = [
    {
        id: 1,
        src: homeImageDir + '/Optimize.svg',
        heading: 'Optimize Business Operations',
        content: 'Our products reduce the cost of your business operations and build business continuity. It give you insights that help you optimize your operations and processes',
    },
    {
        id: 2,
        src: homeImageDir + '/Access.svg',
        heading: 'Access Quality Products',
        content: 'With us, you get access to quality products that help you achieve your goals. Our products require little effort to deploy  and need no prior technical knowledge',
    },
    {
        id: 3,
        src: homeImageDir + '/Create.svg',
        heading: 'Create Value',
        content: 'Our products help accelerate your team’s efficiency and productivity. With little deployment & training effort, you will start seeing efficiency in your operations & serve your clients more effectively',
    },
    {
        id: 4,
        src: homeImageDir + '/Easy.svg',
        heading: 'Easy Onboarding',
        content: 'Our products are easy to implement and use. They will be up and running in a matter of minutes and require minimal training',
    },
];

const OUR_PROMISE = [
    {
        id: 1,
        src: homeImageDir + '/HighEfficiency.svg',
        heading: 'High Efficency',
        content: 'Our products are designed to help you manage your people and processes most efficiently.',
    },
    {
        id: 2,
        src: homeImageDir + '/ReduceCost.svg',
        heading: 'Reduce Cost',
        content: 'Our products reduce your business operation cost while delivering uncompromising quality.',
    },
    {
        id: 3,
        src: homeImageDir + '/EaseOfUsage.svg',
        heading: 'Ease of Usage',
        content: 'They are easy to use and seamlessly integrate with your current IT infrastructure and processes.',
    },
    {
        id: 4,
        src: homeImageDir + '/ReasonablePrices.svg',
        heading: 'Reasonable Prices',
        content: 'Get access to quality tools at affordable prices so that you and your team can achieve the goals you have been wishing for.',
    },
]

export { WHY_US, OUR_PROMISE };