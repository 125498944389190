import React from 'react';

import Hero from '../../components/Hero';
import Section1 from '../../components/Section1';
import Section2 from '../../components/Section2';
import Section3 from '../../components/Section3';
import Pricing from '../../components/Pricing';

import { DS_KEY_FEATURES, DS_BENEFITS } from '../../constants/ProductsConstants';

const DS = () => {
    return (
        <div className='tenie-ds'>
            <Hero>
                <div className={`ds-hero relative`}>
                    <img className='hero-text md:scale-[0.6]' src='/assets/Products/DS/HeroText.svg' alt='heading' />
                </div>
            </Hero>
            <Section1 heading={'Benefits'} page={'ds'} pageData={DS_BENEFITS} />
            <Section2 heading={'Key Features'} pageData={DS_KEY_FEATURES} page={'ds'} />
            <Section3 page={'ds'}
                imgSrc={'/assets/Products/DS/FreeTrial.jpg'}
                content1={'Streamline Innovation, Unleash Efficiency: Empowering Product Development with Our PDM Software '}
                content2={'Try fully functional Tenie PDM before making a purchase decision. No credit card is required. '}
                link={'https://pdm.tenie.co/'}
            />
            <Pricing />
        </div>
    )
}

export default DS