const dsImagePath = '/assets/Products/DS';

const DS_BENEFITS = [
    {
        id: 1,
        src: dsImagePath + '/Benefit1.svg',
        heading: 'Build Robust Data Security',
        content: 'Our PDM software implements access controls and permissions, ensuring that sensitive product data is accessible only to authorized personnel.',
    },
    {
        id: 2,
        src: dsImagePath + '/Benefit2.svg',
        heading: 'Enhanced Global Collaboration',
        content: 'Manage and improve collaboration among cross-functional teams by providing a platform to share, review, and comment on product data in real time.',
    },
    {
        id: 3,
        src: dsImagePath + '/Benefit3.svg',
        heading: 'Gain Full Control',
        content: 'PDM provides a system that allows you effectively manage and control your product data. It enables you to ensure that all product data is current and stored in an easily searchable manner to ensure that everyone works on the latest version.',
    },
    {
        id: 4,
        src: dsImagePath + '/Benefit4.svg',
        heading: 'Centralized Data Management',
        content: 'PDM allows a single, centralized repository for all product-related data, including CAD files, documents, specifications, and other related information which contributes to data consistency and prevents data duplication.',
    },
];

const DS_KEY_FEATURES = [
    {
        id: 1,
        src: dsImagePath + '/Feature1.svg',
        heading: '',
        content: ' Easy integration with other business systems enhances data flow and eliminates silos.',
    },
    {
        id: 2,
        src: dsImagePath + '/Feature2.svg',
        heading: '',
        content: 'PDM system can accommodate growing data volumes and handle the demands of larger teams',
    },
    {
        id: 3,
        src: dsImagePath + '/Feature3.svg',
        heading: '',
        content: 'Seamless collaboration among team members, allowing them to access, review, and comment',
    },
    {
        id: 4,
        src: dsImagePath + '/Feature4.svg',
        heading: '',
        content: 'Automation streamlines processes by automating routine tasks. It reduces manual errors and improves efficiency.'
    },
];

const almImagePath = '/assets/Products/ALM';

const ALM_BENEFITS = [
    {
        id: 1,
        src: almImagePath + '/Benefit1.svg',
        heading: 'Gain 360 Visibility of Asset Portfolio',
        content: '360-degree visibility of your asset portfolio to track the comple life cycle of all your assets, from small tools to big machines ',
    },
    {
        id: 2,
        src: almImagePath + '/Benefit2.svg',
        heading: 'Tax Return Made Easy',
        content: 'Get Depreciation schedules at click of the button to ease your tax filing process ',
    },
    {
        id: 3,
        src: almImagePath + '/Benefit3.svg',
        heading: 'Increase Spend Efficiency',
        content: 'With continuous track of break downs & service spend, you can increase your efficiency by ensuring preventive maintenance and timely replacements',
    },
    {
        id: 4,
        src: almImagePath + '/Benefit4.svg',
        heading: 'Increase Productivity',
        content: 'Track your asset health in real-time to ensure that all assets are being efficiently used and breakdown time is minimized',
    },
];

const ALM_KEY_FEATURES = [
    {
        id: 1,
        src: almImagePath + '/Feature1.svg',
        heading: '',
        content: 'Capture all Assets by status & users  ',
    },
    {
        id: 2,
        src: almImagePath + '/Feature2.svg',
        heading: '',
        content: 'Track capital & operational spend ',
    },
    {
        id: 3,
        src: almImagePath + '/Feature3.svg',
        heading: '',
        content: 'Generate depreciation reports for annual filing',
    },
    {
        id: 4,
        src: almImagePath + '/Feature4.svg',
        heading: '',
        content: 'Gain visibility across the whole asset lifecycle - from procurement to scrappage'
    },
];

export { DS_KEY_FEATURES, DS_BENEFITS, ALM_BENEFITS, ALM_KEY_FEATURES };