import React from 'react';

import Hero from '../../components/Hero';
import Section1 from '../../components/Section1';
import Section2 from '../../components/Section2';
import Section3 from '../../components/Section3';
import Pricing from '../../components/Pricing';

import { ALM_KEY_FEATURES, ALM_BENEFITS } from '../../constants/ProductsConstants';

const ALM = () => {
    return (
        <div className='tenie-alm'>
            <Hero>
                <div className={`alm-hero relative`}>
                    <img className='md:scale-[0.6] hero-text' src='/assets/Products/ALM/HeroText.svg' alt='heading' />
                </div>
            </Hero>
            <Section1 heading={'Benefits'} page={'alm'} pageData={ALM_BENEFITS} />
            <Section2 heading={'Key Features'} pageData={ALM_KEY_FEATURES} page={'alm'} />
            <Section3 page={'alm'}
                imgSrc={'/assets/Products/ALM/FreeTrial.jpg'}
                content1={'Get your Assets’ lifecycle under control, and increase their efficiency.'}
                content2={'Try fully functional Tenie ALM before making a purchase decision. No credit card is required. '}
                link={'https://alm.tenie.co/'}
            />
            <Pricing />
        </div>
    )
}

export default ALM