import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';

const Pricing = () => {

    const PRICING = [
        {
            id: 1,
            src: '/assets/Products/Commercial.svg',
            heading: 'Commerical',
            content: 'All sizes of businesses require integrated solutions to support innovation and business expansion. Our products are simple to use and learn, and together they can help you design faster, better, and economically. Contact us to discuss your unique needs.',
        },
        {
            id: 2,
            src: '/assets/Products/Young-Entrepreneurs.svg',
            heading: 'Young Entrepreneurs',
            content: 'We have solutions for young entrepreneurs and startups to help them grow and run their businesses better without spending a fortune. We help you bring your ideas to reality by designing solutions that best fit your unique business needs.',
        }
    ];

    const aniRef = useRef();
    const [showAni, setShowAni] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setShowAni(entry.isIntersecting);
        });
        observer.observe(aniRef.current);
    }, []);

    return (
        <section className={`ds-pricing bg-theme-lg section-container`}>
            <h1 className='text-center pb-2'>
                <span className='section-heading bg-white'>
                    Pricing
                </span>
            </h1>
            <div className={`pricing-container grid md:grid-cols-2 gap-20 mt-10`} ref={aniRef}>
                {PRICING.map(data =>
                    <div key={data.id} className={`bg-theme-d9 rounded-lg flex items-start p-4 gap-2 ${showAni && 'pricing-ani'}`}>
                        <img src={data.src} alt='pricing-icon' className='inline' />
                        <div className='relative h-full'>
                            <h1 className='sub-heading py-3 text-theme-blue'>
                                {data.heading}
                            </h1>
                            <p className='sub-heading-content mb-16'>
                                {data.content}
                            </p>
                            <button className='text-center crumb text-theme-blue bg-white px-5 py-2 block absolute right-0 bottom-0'>
                                <Link to='/contact'>
                                    Request a quote
                                </Link>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default Pricing