import React from 'react'

const Hero = ({ children }) => {
    return (
        <div className='hero grid p-5 pt-12 md:p-20 md:pt-32 items-center hero-section h-[60vh]'>
            {children}
        </div>
    )
}

export default Hero