import React from "react";
import { Link } from "react-router-dom";

const Section3 = ({ content1, content2, page, imgSrc, link }) => {
	const imgStyle = {
		backgroundImage: `url("${imgSrc}")`,
		backgroundPosition: "top",
		backgroundRepeat: "no-repeat",
	};
	console.log(link);
	return (
		<section className={`${page + "-s3"}`}>
			<div className={`grid md:grid-cols-5 bg-[#4E4F50]`}>
				<div
					className='s3-img-container col-span-3 h-80 sm:h-full bg-auto sm:bg-cover sm:bg-fixed'
					style={imgStyle}></div>
				<div className='content col-span-2 p-8 md:p-16 text-center text-white bg-[#4E4F50] self-center'>
					<p>
						{content1}
						<br />
						<br />
						{content2}
					</p>

					<button className='crumb bg-theme-blue border-white border-2 p-4 px-14 mt-10' onClick={() => window.location.href = "https://alm.tenie.co"}>
						<a href={link} >Start your 30 Days <br /> Free Trial</a>
					</button>
				</div>
			</div>
		</section>
	);
};

export default Section3;
