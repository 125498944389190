import React, { useEffect, useRef, useState } from 'react'

const Section1 = ({ heading, pageData, page }) => {

    const aniRef = useRef();
    const [showAni, setShowAni] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setShowAni(entry.isIntersecting);
        });
        observer.observe(aniRef.current);
    }, []);

    return (
        <section className='orange-section xl:px-32 section-container'>
            <h1 className='text-center pb-2'>
                <span className='section-heading bg-white'>
                    {heading}
                </span>
            </h1>
            <div className={`${page + '-or'} grid md:grid-cols-3 md:grid-rows-2`} ref={aniRef}>
                <div className={`oi flex items-start md:mt-10 mt-5 py-5 gap-x-2 ${showAni && 'orange-ani'}`}>
                    <div className=''>
                        <img className='h-16 w-16 basis-1/5' src={pageData[0].src} alt='benefit' />
                    </div>
                    <div className='basis-4/5 h-full border-b border-solid border-white pb-4'>
                        <h3 className='sub-heading pb-1'>
                            {pageData[0].heading}
                        </h3>
                        <p className='sub-heading-content h-full'>
                            {pageData[0].content}
                        </p>
                    </div>
                </div>

                <div className='row-span-2 mt-10 md:block hidden'>
                    <img className='mx-auto h-full' src="/assets/Timeline.svg" alt="" />
                </div>

                <div className={`oi flex items-start md:mt-10 mt-5 py-5 gap-x-2 ${showAni && 'orange-ani'}`}>
                    <div className=''>
                        <img className='h-16 w-16 basis-1/5' src={pageData[1].src} alt='benefit' />
                    </div>
                    <div className='basis-4/5 h-full border-b border-solid border-white pb-4'>
                        <h3 className='sub-heading pb-1'>
                            {pageData[1].heading}
                        </h3>
                        <p className='sub-heading-content h-full'>
                            {pageData[1].content}
                        </p>
                    </div>
                </div>
                <div className={`oi flex items-start md:mt-10 mt-5 py-5 gap-x-2 ${showAni && 'orange-ani'}`}>
                    <div className=''>
                        <img className='h-16 w-16 basis-1/5' src={pageData[2].src} alt='benefit' />
                    </div>
                    <div className='basis-4/5 h-full border-b border-solid border-white pb-4'>
                        <h3 className='sub-heading pb-1'>
                            {pageData[2].heading}
                        </h3>
                        <p className='sub-heading-content h-full'>
                            {pageData[2].content}
                        </p>
                    </div>
                </div>
                <div className={`oi flex items-start md:mt-10 mt-5 py-5 gap-x-2 ${showAni && 'orange-ani'}`}>
                    <div className=''>
                        <img className='h-16 w-16 basis-1/5' src={pageData[3].src} alt='benefit' />
                    </div>
                    <div className='basis-4/5 h-full border-b border-solid border-white pb-4'>
                        <h3 className='sub-heading pb-1'>
                            {pageData[3].heading}
                        </h3>
                        <p className='sub-heading-content h-full'>
                            {pageData[3].content}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section1