import React from 'react';

import Hero from '../../components/Hero';

const EDM = () => {
    return (
        <div className='tenie-EDM'>
            <Hero>
                <div className={`EDM-hero relative`}>
                    <img className='md:scale-[0.6] hidden md:block hero-text' src='/assets/Products/EDM/HeroText.svg' alt='heading' />
                </div>
            </Hero>
            <div className='grid justify-center items-center h-[50vh]'>
                <h1 className='heading-1xl capitalise text-theme-blue text-4xl py-4 border-b-4 border-theme-bo border-solid font-medium coming-soon'>WE'RE COMING SOON!</h1>
            </div>
        </div>
    )
}

export default EDM