import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import axios from 'axios'

import { useAuth } from '../../context/auth-context';
import { apiUrl } from '../../api'
import { FLASH_SUCCESS, FLASH_ERROR } from '../../constants/actionTypes'

import './login.css'

function Login() {

    const [logUserData, setlogUserData] = useState({ username: '', password: '' })

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const auth = useAuth()

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const config = {
                withCredentials: true,
                headers: {
                    'Type': 'application/json',
                },
            };
            const { data } = await axios.post(`${apiUrl}/login`, logUserData, config)
            console.log(data.type === "success")


            const redirectedPath = location.state?.path || '/'
            if (data.type === "success") {
                auth.logIn(data.data)
                dispatch({ type: FLASH_SUCCESS, payload: data.message })
            }
            else if (data.type === "error") {
                console.log("happened")
                dispatch({ type: FLASH_ERROR, payload: data.message })
            }

            navigate(redirectedPath, { replace: true })

        }
        catch (err) {
            console.log(err.message)
        }
    }
    return (
        <div className='login-page'>
            <div className='login-header mt-24' />
            <div className='logic-section'>
                <form onSubmit={handleSubmit} className='flex items-center justify-center'>
                    <div className='flex flex-col justify-start items-center' style={{ height: "90vh", paddingTop: "120px" }}>
                        <div className='flex flex-col justify-between items-start'>
                            <div className="my-2">
                                <div className="form-group has-placeholder">
                                    <div className='login-field-label'>Username</div>
                                    <input
                                        type="text"
                                        className='login-field-input'
                                        value={logUserData.username}
                                        onChange={(e) => setlogUserData({ ...logUserData, username: e.target.value })}
                                        name="username"
                                        placeholder="Enter Username"
                                    />
                                </div>
                            </div>
                            <div className=" my-2">
                                <div className="form-group has-placeholder">
                                    <div className='login-field-label'>Password</div>
                                    <input
                                        type="password"
                                        className='login-field-input'
                                        value={logUserData.password}
                                        onChange={(e) => setlogUserData({ ...logUserData, password: e.target.value })}
                                        name="password"
                                        placeholder="Enter Password"
                                    />
                                </div>
                            </div>
                            <div style={{ width: "100%" }} className="flex items-center justify-between my-3">
                                <button className="login-btn" type="submit">Login</button>
                                <Link className='register-link' to='/register'>Register</Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login