import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Spinner from '../../components/Spinner'

import { getBlogs, deleteBlog } from '../../actions/blogs'

import './adminBlogs.css'

function AdminBlogs() {
    const dispatch = useDispatch()
    const allBlogs = useSelector((state) => state.blogs)
    const handleDelete = async (blog) => {
        dispatch(deleteBlog(blog))
    }

    useEffect(() => {
        dispatch(getBlogs())
    }, [dispatch])

    return (
        <div className='adminblogscontainer'>
            <div className='flex w-full mx-0'>
                <div className='w-1/12 blogs-headings'>S.No.</div>
                <div className='w-1/4 blogs-headings'>Blog Name</div>
                <div className='w-1/6 blogs-headings'>Author</div>
                <div className='w-1/6 blogs-headings'>Publish Date</div>
                <div className='w-1/6 blogs-headings'>Edit</div>
                <div className='w-1/6 blogs-headings' style={{ borderRight: "0" }}>Delete</div>
            </div>
            {
                allBlogs.length ?
                    allBlogs.slice(0).reverse().map((blog, index) => {

                        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                        const str = blog.date;
                        const date = new Date(str)
                        return <div key={index} className='flex w-full mx-0'>
                            <div className='w-1/12 blog-info'>{index + 1}</div>
                            <div className='w-1/4 blog-info'>{blog.title.substring(0, 25)}...</div>
                            <div className='w-1/6 blog-info'>{blog.username}</div>
                            <div className='w-1/6 blog-info'>{`${date.getDate()} ${month[date.getMonth()]}, ${date.getFullYear()}`}</div>
                            <div className='w-1/6 blog-info'>
                                <Link to={`/resources/editBlogs/${blog._id}`} className={`blog-btn blog-pst-btn`}>Edit</Link>
                            </div>
                            <div className='w-1/6 blog-info' style={{ borderRight: "0" }}>
                                <button onClick={() => handleDelete(blog)} className='blog-btn blog-ngt-btn'>Delete</button>
                            </div>
                        </div>

                    }) : <div className='flex justify-center al my-5' style={{ height: `662.26px`, paddingTop: "250px" }}><Spinner /></div>
            }
        </div>
    )
}

export default AdminBlogs