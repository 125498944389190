const NavLinks = [
    {
        id: 1,
        name: 'Home',
        to: '/',
        dropdown: null
    },
    {
        id: 2,
        name: 'About',
        to: '/about',
        dropdown: null,
    },
    {
        id: 3,
        name: 'Products',
        to: '#',
        dropdown: [
            {
                id: 31,
                name: 'Tenie PDM',
                to: '/teniePDM',
            },
            {
                id: 32,
                name: 'Tenie ALM',
                to: '/tenieALM',
            },
            {
                id: 33,
                name: 'Tenie EDM',
                to: '/tenieEDM',
            },
        ],
    },
    {
        id: 4,
        name: 'Resources',
        to: '/resources',
        dropdown: null,
    },
    {
        id: 5,
        name: 'Contact',
        to: '/contact',
        dropdown: null,
    },
];

export { NavLinks };