import React from 'react';

import Hero from '../components/Hero';

const Features = [
    null,
    {
        id: 3,
        heading: 'Enhance Emloyees Experience',
        content: ' We have designed products keeping the end-user in mind. Our products are easy to use, navigate, and integrate without any prior expertise or training',
        color: '#0071BB',
    },
    null,
    {
        id: 4,
        heading: 'Scale Up, Scale Down',
        content: 'We provide scalable solutions for business of any size. You can can start with access for 1 person and change it anytime basis your requirement',
        color: '#F6921E',
    },
    {
        id: 1,
        heading: 'Reduce Cost',
        content: 'Our products help you reduce upto 90% of  time in completing desired activities. Combine that with our low cost model, you can achieve unparalleled cost advantage while ensuring high effectiveness',
        color: '#BD373D',
    },
    null,
    {
        id: 2,
        heading: 'Access Anywhere, Anytime',
        content: 'All our products are hosted on secure Cloud services enabling work from anywhere. Your employees can connect and access the data from anywhere in the world at anytime',
        color: '#2E3191',
    },
    null
]

const Feature2 = [
    {
        id: 1,
        heading: 'Reduce Cost',
        src: 'one.svg',
        content: 'Our products help you reduce upto 90% of  time in completing desired activities. Combine that with our low cost model, you can achieve unparalleled cost advantage while ensuring high effectiveness',
        color: '#0f6b84',
    },
    {
        id: 2,
        heading: 'Access Anywhere, Anytime',
        src: 'two.svg',
        content: 'All our products are hosted on secure Cloud services enabling work from anywhere. Your employees can connect and access the data from anywhere in the world at anytime',
        color: '#e3842c',
    },
    {
        id: 3,
        heading: 'Enhance Emloyees Experience',
        src: 'three.svg',
        content: ' We have designed products keeping the end-user in mind. Our products are easy to use, navigate, and integrate without any prior expertise or training',
        color: '#0f6b84',
    },
    {
        id: 4,
        heading: 'Scale Up, Scale Down',
        src: 'four.svg',
        content: 'We provide scalable solutions for business of any size. You can start with access for 1 person and change it anytime basis your requirement',
        color: '#e3842c',
    },
]

const About = () => {
    return (
        <div className='about'>
            <Hero>
                <h1 className='hero-heading uppercase text-white fadeInDown'>
                    Scalable products to digitize <br /> and optimize operations
                </h1>
            </Hero>
            {/* <div className='about-features grid grid-cols-4 m-20 grid-rows-2 gap-y-60 mx-12'>
                {Features.map((feature, idx) =>
                    feature ? <div className={`flex items-start gap-x-2 about-item`} key={idx}>
                        <span className={`rounded-full text-white grid place-items-center aspect-square w-6 h-6 drop-shadow-2xl`}
                            style={{
                                backgroundColor: feature.color,
                                boxShadow: "1px 5px 5px 5px rgba(0,0,0,0.2)"
                            }}
                        >{feature.id}</span>
                        <div className='w-full'>
                            <h1 className={`sub-heading text-[${feature.color}]`}>
                                {feature.heading}
                            </h1>
                            <p className='sub-heading-content'>
                                {feature.content}
                            </p>
                        </div>
                    </div> : <div key={idx}></div>
                )}
            </div> */}

            <div className='grid md:m-10 my-6 mx-0 gap-y-10 md:gap-y-20 grid-rows-4 relative'>
                {Feature2.map((feature, idx) =>
                    <div className={`about-content relative opacity-0 flex flex-row-reverse w-[61.9%] items-start text-right md:gap-x-4 gap-x-2 z-1`} key={idx}>
                        <div className='about-img-container basis-2/5'>
                            <img src={`/assets/About/${feature.src}`} className='' alt='' />
                        </div>
                        <div className='basis-3/5'>
                            <h1 className={`sub-heading`} style={{ color: feature.color }}>
                                {feature.heading}
                            </h1>
                            <p className='sub-heading-content pt-2'>
                                {feature.content}
                            </p>
                        </div>
                    </div>
                )}
                <div className='absolute h-full top-0 left-[54%] md:left-[50%] z-[-1] -translate-x-1/2'>
                    <img src='/assets/About/line.svg' className='object-cover h-full w-1/2 md:w-full' alt='' />
                </div>
            </div>
        </div>
    )
}

export default About