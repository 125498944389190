import React from 'react';

import Hero from '../components/Hero';
import Section1 from '../components/Section1';
import Section2 from '../components/Section2';

import { OUR_PROMISE, WHY_US } from '../constants/HomeConstansts';

const Home = () => {
    return (
        <div className='home'>
            <Hero>
                <h1 className='hero-heading uppercase text-theme-bo fadeInDown'>
                    Digital Transformation
                    <br />
                    Products <span className='text-black'>at reasonable <br /> prices</span>
                </h1>
            </Hero>
            {/* Why Us? */}
            <Section1 heading={'Why Us?'} pageData={WHY_US} page={'home'} />
            {/* Our Promise */}
            <Section2 heading={'Our Promise'} pageData={OUR_PROMISE} page={'home'} />
        </div>
    )
}

export default Home